// .btn {
//   padding-left: 20px;
//   padding-right: 20px;
// }
.btn-primary {
	background-color: $primary;
	border: 1px solid $primary;
	color: $white;

	&:hover,
	&:focus,
	&:active {
		border: 1px solid $primary !important;
		background-color: $primary !important;
		box-shadow: none !important;
	}
}
.btn-primary-200 {
	background-color: $primary-200;
	border: 1px solid $primary-200;
	color: $white;

	&:hover,
	&:focus,
	&:active {
		border: 1px solid $primary-200 !important;
		background-color: $primary-200 !important;
		box-shadow: none !important;
	}
}
.btn-secondary {
	background-color: $secondary;
	border: 1px solid $secondary;
	color: $white;
	&:hover,
	&:focus,
	&:active {
		order: 1px solid $secondary !important;
		background-color: $secondary !important;
		box-shadow: none !important;
	}
}
.btn-danger {
	background-color: $danger;
	border: 1px solid $danger;
	color: $white;
	&:hover,
	&:focus,
	&:active {
		border: 1px solid $danger !important;
		background-color: $danger !important;
		box-shadow: none !important;
	}
}
.btn-info {
	background-color: $info;
	border: 1px solid $info;
	color: $white;
	&:hover,
	&:focus,
	&:active {
		border: 1px solid $info !important;
		background-color: $info !important;
		box-shadow: none !important;
	}
}
.btn-warning {
	background-color: $warning;
	border: 1px solid $warning;
	color: $light;
	&:hover,
	&:focus,
	&:active {
		border: 1px solid $warning !important;
		background-color: $warning !important;
		box-shadow: none !important;
	}
}
.btn-success {
	background-color: $success;
	border: 1px solid $success;
	color: $white;
	&:hover,
	&:focus,
	&:active {
		border: 1px solid $success !important;
		background-color: $success !important;
		box-shadow: none !important;
	}
}
.btn-dark {
	background-color: $dark;
	border: 1px solid $dark;
	color: $white;

	&:hover,
	&:focus,
	&:active {
		border: 1px solid $dark !important;
		background-color: $dark !important;
		box-shadow: none !important;
	}
}

.btn-lg {
	padding: 0.5rem 1rem;
	font-size: 1.25rem;
	line-height: 1.5;
}
.btn-round {
	border-radius: 30px;
}
.btn-outline-primary {
	background-color: transparent;
	color: $primary;
	border: 1px solid $primary;
	&:hover,
	&:focus,
	&:active {
		color: $white;
		border: 1px solid $primary !important;
		background-color: $primary !important;
		box-shadow: none !important;
	}
}
.btn-outline-primary-100 {
	background-color: transparent;
	color: $primary-100;
	border: 1px solid $primary-100;
	&:hover,
	&:focus,
	&:active {
		color: $white !important;
		border: 1px solid $primary-200 !important;
		background-color: $primary-200 !important;
		box-shadow: none !important;
	}
}
.btn-outline-primary-200 {
	background-color: transparent;
	color: $primary-200;
	border: 1px solid $primary-200;
	&:hover,
	&:focus,
	&:active {
		color: $white !important;
		border: 1px solid $primary-200 !important;
		background-color: $primary-200 !important;
		box-shadow: none !important;
	}
}
.btn-outline-secondary {
	background-color: transparent;
	color: $secondary;
	border: 1px solid $secondary;
	&:hover,
	&:focus,
	&:active {
		order: 1px solid $secondary !important;
		background-color: $secondary !important;
		box-shadow: none !important;
	}
}
.btn-outline-danger {
	background-color: transparent;
	color: $danger;
	border: 1px solid $danger;
	&:hover,
	&:focus,
	&:active {
		border: 1px solid $danger !important;
		background-color: $danger !important;
		box-shadow: none !important;
		color: white;
	}
}
.btn-outline-info {
	background-color: transparent;
	color: $info;
	border: 1px solid $info;
	&:hover,
	&:focus,
	&:active {
		border: 1px solid $info !important;
		background-color: $info !important;
		box-shadow: none !important;
	}
}
.btn-outline-warning {
	background-color: transparent;
	color: $warning;
	border: 1px solid $warning;
	&:hover,
	&:focus,
	&:active {
		border: 1px solid $warning !important;
		background-color: $warning !important;
		box-shadow: none !important;
	}
}

// .dropdown {
//   .btn-primary {
//     background-color: transparent;
//     color: $primary;
//     padding: 0;
//     border: none;
//     &:hover,
//     &:focus,
//     &:active {
//       background-color: transparent !important;
//       opacity: 0.9;
//       box-shadow: none !important;
//       transform: translateY(-1px);
//       color: $primary !important;
//       outline: transparent;
//       border: none !important;
//     }
//   }
// }
.btn-sm {
	display: inline;
}

.btn-clarify {
	background-color: #f0dba4;
	border-radius: 32px;
	color: $secondary;
	border: #f0dba4;
	padding: 5px 20px;
	margin-left: 10px;
	font-size: 12px;
	border: 1px solid #f0dba4;
}
.btn-astrologer {
	background-color: #decdc3;
	border: 1px solid #decdc3;
	border-radius: 32px;
	color: $secondary;
	border: #decdc3;
	padding: 5px 20px;
	margin-left: 10px;
	font-size: 12px;
}
.btn-answer {
	background-color: #eaf7e3;
	border: 1px solid #eaf7e3;
	border-radius: 32px;
	color: $secondary;
	border: #eaf7e3;
	padding: 5px 20px;
	margin-left: 10px;
	font-size: 12px;
}
.btn-cancel {
	background-color: $primary-200;
	border: 1px solid $primary-200;
	border-radius: 32px;
	color: $white;
	border: #eaf7e3;
	padding: 5px 20px;
	margin-left: 10px;
	font-size: 12px;
}

.deleteIcon {
	position: absolute;
	top: 0;
	right: 0;
	background-color: $primary;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	display: grid;
	place-items: center;
	box-shadow: $box-shadow;
	color: #fff;
	z-index: 99;
	&:hover {
		transform: scale(1.5);
	}
}

.show > .btn-primary.dropdown-toggle {
	color: $primary;
	background-color: transparent;
	border: none;
}

.customer-file-input {
	// color: transparent;
	display: flex;
}
.customer-file-input::-webkit-file-upload-button {
	visibility: hidden;
}
.customer-file-input::before {
	content: 'Browse Image';
	color: $primary;
	display: inline-block;
	text-align: center;
	background: -webkit-linear-gradient(top, #f9f9f9, #e3e3e3);
	border: 1px solid $primary;
	border-radius: 3px;
	padding: 5px 8px;
	outline: none;
	white-space: nowrap;
	cursor: pointer;
	font-weight: 700;
	font-size: 10pt;
}
.customer-file-input:hover::before {
	border-color: black;
}
.customer-file-input:active {
	outline: 0;
}
.customer-file-input:active::before {
	background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

.swal-button--danger {
	background-color: $primary;
	border: 1px solid $primary;
}
.swal-button {
	background-color: $primary;
	border: 1px solid $primary;
	color: $white;
	border-radius: 32px;
	&:hover,
	&:focus,
	&:active {
		background-color: $primary-200 !important;
		border: 1px solid $primary-200 !important;
		box-shadow: none;
	}
}
.swal-button--cancel {
	background-color: $secondary;
	border: 1px solid $secondary;
	&:hover,
	&:focus,
	&:active {
		background-color: $light !important;
		border: 1px solid $light !important;
		color: $white;
	}
}

.btn-info-popover {
	background-color: transparent;
	padding: 0;
	margin: 0;
	border: none;
	&:active,
	&:focus {
		outline: none;
	}
}
